<template>

	<div class="app-container" ref="container">
		<!-- home -->
		<div class="top">

			<div class="logo">
				<div class="img">
					<img style="width: 100%; height: 100%;" src="../../assets/indexLogo.png" alt="">
				</div>
				<div class="title">
					Task Monkey
				</div>
			</div>
			<div class="list">
				<div class="item" @click="goToAnchor('home')">Home</div>
				<div class="item" @click="goToAnchor('how')">How to use it</div>
				<div class="item" @click="goToAnchor('pricing')">Pricing</div>
				<div class="item" @click="goToAnchor('our')">Our mission</div>
			</div>
			<div class="hov">
				<span @click="show = !show">
					<i class="iconfont icon-mulu"></i>
				</span>
			</div>
			<div>
				<button style="cursor:pointer" class="but" @click="openTaskMonkey">Open Task Monkey</button>
			</div>


		</div>
		<div style="height: 70px;"></div>

		<el-collapse-transition>
			<div class="list2" v-if="show">
				<div class="item" @click="goToAnchor('home')">Home</div>
				<div class="item" @click="goToAnchor('how')">How to use it</div>
				<div class="item" @click="goToAnchor('pricing')">Pricing</div>
				<div class="item" @click="goToAnchor('our')">Our mission</div>
				<div style="height: 900px;"></div>

			</div>
		</el-collapse-transition>
		<div class="first" id="home">
			<div class="left">

				<div class="title">
					Automate your chats
				</div>
				<div class="description" style="font-family: 'poppins';">
					Freeing you to focus on what truly matters
				</div>
				<div class="other" style="font-family: 'poppins';">
					Chat less, do more
				</div>
				<div>

					<button class="but" style="margin-top: 30px;     padding-left: 20px; cursor:pointer">Try for free</button>
				</div>

			</div>
			<div class="right">
				<img style="width: 100%; height: 100%;" src="@/assets/2.png" alt="">
			</div>
		</div>
		<div class="second sow" id="how">
			<!-- <div class="" > -->
			<div class="left">
				<div class="img">
					<img style="width: 100%; height: 100%;" src="@/assets/1.png" alt="">

				</div>
				<div class="title">
					How to Use
				</div>
				<div class="title">
					Task Monkey?
				</div>
				<div class="description">
					Just 3 simple steps to automate your chats,
					<br>
					let AI do your job.
				</div>
				<div>
					<button  class="but" style="  margin-left: 20px;cursor:pointer" @click="openTaskMonkey">Sign up now</button>
				</div>

			</div>
			<div class="right">
				<div class="list">
					<div class="item">
						<div class="title">Step 1</div>
						<div class="description">
							Chat with Task Monkey agent about your purpose and provide all your background information.
						</div>
					</div>
					<div class="item">
						<div class="title">Step 2</div>
						<div class="description">
							Start your task with just one click.
						</div>
					</div>
					<div class="item">
						<div class="title">Step 3</div>
						<div class="description">
							Bingo! Check the results in your email or on our website.
						</div>
					</div>
				</div>
				<!-- </div> -->
			</div>

		</div>
		<div class="third">
			<div class="title" id="pricing">Pricing</div>
			<div class="description">
				One tool to manage all your chats.
			</div>
			<div style="cursor:pointer" class="description" @click="openTaskMonkey">
				Try it for free.
			</div>
			<div class="list">
				<div class="item sow">
					<div class="title">
						Free
					</div>
					<div class="all">
						<div class="money">$</div>
						<div class="num">0</div>
					</div>

					<div class="description">
						No Credit Card Required
					</div>
					<div class="app" style="width: 80%;     text-align: center; margin: 0 auto; margin-top: 50px;">

						<button style="cursor:pointer" class="but-us" @click="openTaskMonkey">Get Started with Free Plan</button>
					</div>
				</div>
				<div class="item sow" style="    z-index: 100;">
					<div class="title">
						Pro
					</div>
					<div class="all">
						<div class="money">$</div>
						<div class="num">9.9
							<span>/month</span>
						</div>
					</div>

					<div class="description">
						For Productivity-focused Users
					</div>
					<div class="app" style="width: 80%;     text-align: center; margin: 0 auto; margin-top: 55px;">

						<button style="cursor:pointer" class="but" @click="openTaskMonkey">Get Started with Pro</button>
					</div>
					<div class="ab">
						Most Popular
					</div>
				</div>
				<div class="item sow">
					<div class="title">
						Business
					</div>
					<!-- <div class="money">$</div> -->
					<div class="num">Let's talk</div>
					<div class="description">
						Custormized Plan
					</div>
					<div class="app" style="width: 80%;     text-align: center;  margin: 0 auto; margin-top: 50px;">

						<button style="cursor:pointer" class="but-us" @click="openTaskMonkey">Contact Us</button>
					</div>
				</div>
			</div>
		</div>
		<div class="fourth">
			<div class="title">Compare Features</div>
			<!-- <div class="titles">
      </div> -->
			<div class="list">
				<div class="box">

					<div class="title">Basic</div>
					<div class="item">
						<div class="icon">
							<i class="iconfont icon-duihao1"></i>
						</div>

						<div class="title">
							1 Day Free Trial
						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Up-to-date Al Models

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Information Collection

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Automated Chat

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Task Report
						</div>
					</div>
				</div>
				<div class="box">
					<div class="title">Pro</div>

					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Full Functionality

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Up-to-date Al Models

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Information Collection

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Automated Chat

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Task Report

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Multi-task Management
						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Task Notification

						</div>
					</div>
				</div>
				<div class="box">
					<div class="title">Business</div>

					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Customized Plan

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Up-to-date Al Models

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Information Collection

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Automated Chat

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Task Report

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Muiti-task Management

						</div>
					</div>
					<div class="item">
						<div class="icon"> <i class="iconfont icon-duihao1"></i></div>
						<div class="title">
							Task Notification

						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="fifth" id="our">
			<div class="title">Our Mission</div>
			<div class="description">
				Our mission is to revolutionize digital interaction with advanced Al services. We aim to free users
				from mundane online chats, allowing them to focus on creativity and productivity, By fully automating
				these interactions, we help users save time and achieve their goals effortlessly. Our vision is to
				create an all-powerful Al agent that seamlessly handles all online services through simple voice
				commands. We are dedicated to making this a reality.
			</div>
			<div class="img">
				<img style="width: 100%; height: 100%;" src="@/assets/4.png" alt="">
			</div>
		</div>
		<div class="sixth">
			<div class="title">Testimonials</div>
			<div class="description">
				People love what we do and we want to let your know
			</div>
			<div class="list">
				<div class="item sow">
					<div class="title">
						"It's efficient, reliable and
						has significantly boosted
						our team's productivity.
						Highly recommend!"
					</div>
					<div class="star">
						<el-rate :colors="color" v-model="value" disabled>
						</el-rate>
					</div>
					<div class="name">
						Alice Johnson
					</div>
					<div class="other">
						CEO of Innovatech
					</div>
				</div>
				<div class="item sow">
					<div class="title">
						"The intelligent assistant
						from Task Monkey has been a
						game-changer. We can
						focus on creativity now!"
					</div>
					<div class="star">
						<el-rate :colors="color" v-model="value" disabled>
						</el-rate>
					</div>
					<div class="name">
						Michael Smith
					</div>
					<div class="other">
						Product Manager, Gadgix
					</div>
				</div>
				<div class="item sow">
					<div class="title">
						"Task Monkey saves us
						countless hours by
						automating online
						tasks.invaluabio asset!"
					</div>
					<div class="star">
						<el-rate :colors="color" v-model="value" disabled>
						</el-rate>
					</div>
					<div class="name">
						David Lee
					</div>
					<div class="other">
						CTO, TechCore
					</div>
				</div>
			</div>
		</div>
		<div class="seventh sow">
			<div class="container">
				<div class="left">
					<div class="title">
						Get started with
					</div>
					<div class="title">
						Task Monkey today
					</div>
					<div class="description">
						Start automating your chat today
					</div>
					<div>
						<button style="cursor:pointer" class="but" @click="openTaskMonkey"> Sign up now</button>
					</div>
				</div>
				<div class="right">
					<div class="img">
						<img style="width: 100%; height: 100%;" src="@/assets/5.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="left">
				<div class="logo">
					<div class="img">
						<img style="width: 100%; height: 100%;" src="../../assets/indexLogo.png" alt="">
					</div>
					<div class="title">
						Task Monkey
					</div>
				</div>
				<div style=" font-size: 14px;margin-top: 20px;white-space: nowrap;">@ 2024 MindSight Lab</div>
				<div style=" font-size: 14px;white-space: nowrap;">All rights reserved </div>
			</div>

			<div class="pages">
				<div style="cursor:pointer" @click="goToAnchor('home')">Home</div>
				<div style="cursor:pointer" @click="goToAnchor('how')">How to use it </div>
				<div style="cursor:pointer" @click="goToAnchor('pricing')">Pricing</div>
				<div style="cursor:pointer" @click="goToAnchor('our')">Our mission</div>
			</div>
			<div class="list">
				<div style="cursor:pointer" @click="opensecurity">Privacy Policy</div>
				<div style="cursor:pointer" @click="openterms">Terms of Service</div>
				<div style="cursor:pointer" @click="openus">Contact Us</div>
			</div>
			<div class="show">
				@ 2024 MindSight Lab
				<br />
				All rights reserved
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'homePage',
		data() {
			return {
				value: 5,
				color: ['#3B6DFC', '#3B6DFC', '#3B6DFC'],
				show: false
			}
		},
		created() {

		},
		mounted() {
			window.addEventListener('scroll', () => {
				if (this.show) {
					this.show = false
				}
			})
		},
		methods: {
			goToAnchor(selector) {
				console.log(selector);
				document.querySelector('#' + selector).scrollIntoView({
					behavior: "smooth",
					block: "center"
				})
			},

			openTaskMonkey() {
				// 打开外部链接
				window.location.href =
					'https://chromewebstore.google.com/detail/task-monkey/johaackbhmenencefcndbkfpfncjaoaf?utm_source=ext_sidebar';
			},
			openterms(){
				// 打开外部链接
				window.location.href =
					'https://taskmonkey.ai/terms-of-service';
			},
			opensecurity(){
				// 打开外部链接
				window.location.href =
					'https://taskmonkey.ai/privacy-policy';
			},
			openus(){
				// 打开外部链接
				window.location.href ='https://taskmonkey.ai/us';
					
			}
		}
	}
</script>
<style scoped lang="scss">
	//  flex-wrap: wrap;
	@media screen and (max-width: 1000px) {
		* {
			font-family: Arial, sans-serif !important;
		}

		body {
			.but {
				font-size: 16px;
				line-height: 16px;
				padding: 0 20px !important;
				width: max-content !important;
			}

			.but-us {
				font-size: 16px;
				line-height: 16px;
				padding: 0 20px !important;
				width: max-content !important;
			}

			.app {
				width: 100% !important;
			}

			.icon {
				width: 25px !important;
				height: 25px !important;
				line-height: 25px !important;

			}

			.app-container {
				width: 100%;
				overflow: hidden;
				padding: 10px;
			}

			.top {
				// flex-wrap: wrap;
				width: 100%;
				padding: 10px;

				// justify-content: space-between !important;
				// .left {
				// width: 100%;
				// margin-bottom: 15px;
				// justify-content: space-between !important;
				.list {
					display: none;
					flex-direction: column;

					.item {
						white-space: nowrap;
					}
				}

				.hov {
					width: 30%;
					text-align: right;
					display: block;
					// margin-left: 80px;
				}

				// }
				.but {
					display: none;
				}
			}

			.first {
				flex-wrap: wrap;
				width: 100%;
				height: auto;
				padding-top: 0;
			}

			.second {
				width: 100%;
				// padding: 0;
				height: max-content;
				margin-top: 50px;
				flex-wrap: wrap;

				.left {
					// padding: 0;
					transform: none;

					.img {
						width: 100%;
					}
				}
			}

			.third {
				.list {
					flex-wrap: wrap;
					width: 100%;
					justify-content: center;

					.item {
						margin-bottom: 20px;
						height: auto !important;
						transform: none !important;
					}
				}
			}

			.fourth {
				width: 100%;
				padding: 0;
				margin: 0;

				.title:nth-child(1) {
					margin-left: 20px;
					margin-bottom: 10px;
				}

				.list {
					.box {
						width: 100%;
						padding-left: 50px;
					}
				}

				.box:nth-child(1) .item {
					transform: translateX(0);
				}

				.box:nth-child(2) .item {
					transform: translateX(0) !important;
				}

				.box:nth-child(3) .item {
					transform: translateX(0) !important;

				}

				.box:nth-child(1) .title:nth-child(1) {
					padding-left: 0 !important;
				}

				.box:nth-child(2) .title:nth-child(1) {
					padding-left: 0 !important;
				}

				.box:nth-child(3) .title:nth-child(1) {
					padding-left: 0 !important;
				}
			}

			.fifth {
				// display: none;
				padding: 0;
				width: 100%;

				.description {
					width: 80%;
					font-size: 16px;
				}

				.img {
					width: 100%;
				}
			}

			.sixth {

				// display: none;
				.list {
					flex-wrap: wrap;
					width: 100%;

					.item {
						margin-bottom: 20px;
						height: auto;
						transform: none !important;
					}
				}
			}

			.seventh {
				width: 90%;
				margin: 20px auto;
				// padding: 30px;
				padding-top: 30px;
				padding-left: 30px;

				.container {
					flex-wrap: wrap;
					text-align: center;

					.right {
						margin: 0;
					}

					.left {
						margin: 0;
						overflow: hidden;

						// padding: 10px;
						.title {
							font-size: 32px;
						}
					}
				}

				.img {
					width: 100% !important;
					margin-top: 40px;
				}
			}

			.footer {
				padding: 10px;
				flex-wrap: wrap;

				.left {
					display: none;
				}

				margin-top: 20px;

				div {
					white-space: nowrap;
				}

				.pages {
					display: none;
				}

				.show {
					width: 100%;
					text-align: center;
					display: block;
					white-space: nowrap;
				}

				.list {
					text-align: center;
					font-weight: none;
				}
			}
		}
	}

	.title {
		font-family: "poppins";
	}

	.description {
		font-family: "Inter";
	}

	.other {
		font-family: "Inter";
	}

	.app-container {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		background-color: #efefef;
	}

	.but {
		// width: 100%;
		height: 40px;
		padding: 10px 25px;
		color: #fff;
		background: #3c6cfb;
		border-radius: 7px;
		border: none;
		font-weight: bold;
		white-space: nowrap;
	}

	.but-us {
		// width: 100%;
		white-space: nowrap;
		height: 40px;
		padding: 10px 25px;
		color: black;
		// background: #3c6cfb;
		border-radius: 7px;
		background: #fff;
		border: 1px solid #ccc;
		font-weight: bold;
	}

	.sow {
		border-radius: 10px;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		background: #fff;
	}

	.top {
		width: 1200px;
		left: 50%;
		transform: translateX(-50%);
		height: 60px;
		padding: 30px;
		display: flex;
		background: #efefef;
		z-index: 190;
		justify-content: space-around;
		align-items: center;
		position: fixed;
		top: 0;

		// .left {
		// display: flex;
		// align-items: center;
		.logo {
			display: flex;
			align-items: center;

			.img {
				width: 30px;
				height: 30px;
				margin-right: 10px;
				// border-radius: 50%;
				overflow: hidden;
			}

			.title {
				font-size: 22px;
				font-weight: bold;
				white-space: nowrap;

			}
		}

		.list {
			display: flex;

			.item {
				margin: 0 10px;
				font-size: 14px;
				font-weight: bold;
				padding: 10px;
				cursor: pointer;
			}
		}

		.hov {
			// width: 100%;
			// height: 100%;
			position: relative;
			display: none;
		}

		// }
	}

	.list2 {
		// transition:all 4s;
		width: 100%;
		// height: 1000px;
		position: fixed;
		// border-radius: 10px;
		// padding-top: 75px;
		// transform: translateY(-5px);
		z-index: 999;
		border-bottom: 1px solid;
		left: 0;
		top: 60px;
		background: #efefef;

		.item {
			margin: 18px 20px;
			white-space: nowrap;
			font-size: 20px;
			font-family: "poppins";
		}
	}

	.first {
		width: 1030px;
		margin: 0 auto;
		height: 400px;
		// padding-top: 70px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		.left {
			margin: 0 40px;

			.title {
				font-size: 50px;
				font-weight: bold;
				margin: 10px 0;
			}

			.description {
				font-size: 26px;
				font-weight: bold;

				margin: 10px 0;
			}

			.other {
				font-size: 12px;
				margin: 10px 0;
				font-weight: bold;

				color: #666666;
			}
		}

		.right {
			margin: 0 10px;
			width: 340px;
		}
	}

	.second {
		padding: 20px;
		width: 1030px;
		height: 500px;
		margin: 0 auto;
		// height: 400px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		margin-top: 200px;
		// padding: 20px;
		// background: #fff;

		.left {
			transform: translateY(-150px);

			// margin-right: 40px;
			// padding-left: 20px;
			.img {
				width: 450px;
			}

			.title {
				padding-left: 20px;

				font-size: 30px;
				font-weight: bold;
				// margin: 5px 0;
			}

			.description {
				padding-left: 20px;

				font-size: 14px;
				width: 230px;
				margin: 20px 0;
				white-space: nowrap;
			}
		}

		.right {
			width: 400px;
			// margin: 20px;
			padding: 20px;

			.list {
				.item {
					padding-bottom: 20px;
					margin-bottom: 20px;
					border-bottom: 1px solid #ccc;

					.title {
						font-size: 24px;
						font-weight: bold;
						margin: 10px 0;
					}

					.description {
						font-size: 14px;
						width: 230px;
						margin: 10px 0;
						line-height: 22px;
						color: #666;
					}
				}

				.item:last-child {
					border: none;
				}
			}
		}
	}

	.third {
		margin-top: 50px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			font-size: 30px;
			font-weight: bold;
			margin: 10px 0;
		}

		.description {
			font-size: 16px;
			// margin: 20px 0;
		}

		.list {
			width: 1030px;
			display: flex;
			margin: 0 auto;
			margin-top: 70px;

			.item {
				width: 340px;
				height: 340px;
				padding: 20px;
				position: relative;

				.title {
					font-size: 16px;
					font-weight: bold;
					margin: 10px 0;
					padding-bottom: 10px;
				}

				.money {
					font-size: 20px;
					font-weight: bold;
				}

				.num {
					font-size: 50px;
					font-weight: bolder;

					span {
						font-size: 14px;
						font-weight: normal;
					}
				}

				.description {
					font-size: 14px;
					margin: 30px 0;
					font-weight: bold;
					line-height: 22px;
					color: #666;
				}

				.ab {
					position: absolute;
					top: 20px;
					right: 10px;
					font-size: 10px;
					font-weight: bold;
					line-height: 25px;
					color: #fff;
					padding: 0 10px;
					height: 25px;
					// line-height: 25px;
					border-radius: 25px;
					background: #3c6cfb;
				}

				.but {
					margin-top: 30px;
					width: 100%;
				}

				.but-us {
					margin-top: 10px;
					width: 100%;

				}

				.all {
					display: flex;
					margin: 15px 0;
				}
			}

			.item:nth-child(2) {

				transform: translateY(-30px);
				padding-top: 15px;
				width: 370px;
				height: 410px;

				.title {
					margin-top: 30px;
				}

			}
		}
	}

	.fourth {
		width: 1100px;
		// transform: translateX(30px);
		margin: 0 auto;
		padding: 50px;
		margin-top: 50px;

		// margin-left: 15%;
		// padding-left: 10%;
		// margin-bottom: 50px;
		.title {
			font-size: 30px;
			font-weight: bold;
			margin: 10px 0;
			margin-bottom: 35px;
		}

		// .titles {
		//   display: flex;

		//   div {
		//     width: 33%;

		//     // text-align: center;
		//   }
		// }
		.list {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;

			// flex-flow: nowrap;
			// border-bottom: 1px solid #ccc;
			.box {
				width: 33%;

				// margin-left: 10px;
				.title {
					font-size: 30px;
					font-weight: bold;
					margin: 20px 0;
				}

				.title:nth-child(1) {
					padding-bottom: 20px;
					padding-left: 75px;

					margin-bottom: 20px;
					border-bottom: 1px solid #ccc;
				}
			}

			.item {
				height: 40px;
				font-size: 16px;
				margin: 10px 0;
				display: flex;
				align-items: center;

				// justify-content: center;
				.icon {
					transform: scale(.8);
					font-size: 14px;
					width: 34px;
					height: 34px;
					margin-right: 10px;
					// padding: 10px;
					border-radius: 50%;
					background: #3c6cfb;
					color: #fff;
					line-height: 34px;
					font-weight: bolder;
					text-align: center;
				}

				.title {
					font-size: 16px;
					font-weight: normal;
				}
			}

			.box:nth-child(1) .item {
				transform: translateX(0);
			}

			.box:nth-child(2) .item {
				transform: translateX(50px);
			}

			.box:nth-child(3) .item {
				transform: translateX(80px);

			}

			.box:nth-child(1) .title:nth-child(1) {
				padding-left: 0;
			}

			.box:nth-child(2) .title:nth-child(1) {
				padding-left: 50px;
			}

		}
	}

	.fifth {
		margin: 20px auto;
		padding: 50px;
		text-align: center;

		.title {
			font-size: 30px;
			font-weight: bold;
			margin: 20px 0;
		}

		.description {
			width: 1000px;
			margin: 0 auto !important;
			font-size: 18px;
			margin: 10px 12%;
			color: #333;
			line-height: 24px;
		}

		.img {
			width: 1100px;
			margin: 0 auto;
			margin-top: 40px;
		}
	}

	.sixth {
		width: 90%;
		margin: 20px auto;
		padding: 30px;
		text-align: center;

		.title {
			font-size: 30px;
			font-weight: bold;
			margin: 20px 0;
		}

		.description {
			font-size: 18px;
			margin: 10px 0;
			color: #333;
			line-height: 24px;
			margin-bottom: 40px;
		}

		.list {
			display: flex;
			width: 1200px;
			margin: 0 auto;
			justify-content: center;
			margin-top: 30px;
			justify-content: space-evenly;

			.item {
				// flex: 0;
				width: 300px;
				// height: 350px;
				// margin: 20px 0;
				// margin-right: 40px;
				padding: 20px;
				padding-bottom: 40px;

				.title {
					font-size: 18px;
					font-weight: bold;
					// line-height: 28px;
				}

				.star {
					text-align: center;
				}

				.name {
					font-size: 16px;
					font-weight: bold;
					margin-right: 4px;
					// margin-top: 20px;
				}

				.other {
					font-size: 14px;
					margin-top: 10px;
					color: #333;
				}
			}

			.item:nth-child(2) {
				transform: translateY(-10px);
			}
		}
	}

	.seventh {
		width: max-content;
		margin: 50px auto;
		// padding-left: 60px;
		text-align: center;

		.container {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.left {
				margin-left: 60px;
				width: 400px;
				white-space: nowrap;
				text-align: left;

				.title {
					font-size: 40px;
					font-weight: bold;
					// margin: 20px 0;

				}

				.description {
					font-size: 18px;
					// margin: 20px 0;
					margin-top: 30px;
					margin-bottom: 45px;

					// margin-bottom: 40px;
					color: #333;
					line-height: 24px;
				}
			}

			.right {
				margin-left: 200px;
				transform: translateY(-40px);

				.img {
					width: 400px;
				}
			}
		}
	}

	.footer {
		justify-content: space-evenly;
		width: 80%;
		margin: 0 auto;
		padding: 50px;
		display: flex;

		.left {
			width: 230px;
			font-size: 18px;

			.logo {
				display: flex;
				align-items: center;

				.img {
					width: 30px;
					height: 30px;
					margin-right: 10px;
					overflow: hidden;
				}

				.title {
					font-size: 22px;
					font-weight: bold;
					white-space: nowrap;
				}
			}
		}

		.pages,
		.list {
			// width: 100%;
			// display: flex;
			// justify-content: space-around;
			font-size: 14px;
			color: #333;
			line-height: 40px;
			font-weight: bold;
		}

		.show {
			display: none;
		}
	}
</style>