<template>
	<div id="app">
		<!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<HOME ref="home" />
	</div>
</template>

<script>
	import HOME from '@/view/HOME/index.vue'

	export default {
		name: 'App',
		components: {
			HOME,
		},

		methods: {

		}
	}
</script>

<style>
	/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: 'Inter';
	}
</style>